import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import preLaunchEventBannerImage from '../../assets/temp/pre-launch-event/pre-launch-event-banner.png';
import preLaunchEventPrizesImage from '../../assets/temp/pre-launch-event/pre-launch-event-prizes.png';
import preLaunchEventDeco1Image from '../../assets/temp/pre-launch-event/pre-launch-event-deco-1.png';
import { Modal } from 'components';
import Icon from 'assets/icons';
import { SessionStorageKey } from 'enums/sessionStorage.enum';
import ReactGA from 'react-ga4';

interface PreLaunchEventModalProps {
	opened: boolean;
}

const PreLaunchEventModal: React.FC<PreLaunchEventModalProps> = ({
	opened,
}) => {
	const { t } = useTranslation();
	const [checked, isChecked] = useState<boolean>(false);

	return (
		<Modal opened={opened} className="bg-color-wizard-blue">
			<div className="py-3 px-2">
				<img src={preLaunchEventBannerImage} className="mx-auto w-[80%]" />
				<div className="md:h-8 h-4" />
				<div className="flex xl:flex-row flex-col-reverse gap-10">
					<div className="flex flex-col xl:items-start items-center">
						<p className="md:text-[32px] text-[14px] text-color-cream-white font-['Bungee'] leading-none">
							{t('win_tcg_prizes_up_to')}
						</p>
						<p className="md:text-[64px] text-[32px] text-color-bright-yellow font-['Bungee']">
							USD $15,000
						</p>
						<p className="md:text-[20px] text-[12px]  text-color-cream-white font-['Bungee']">
							{t('in_our_grand_launch_raffle')}
						</p>
						<p className="md:text-[14px] text-[10px] text-color-white">
							{t('app_release')}: {t('coming_soon')}
						</p>
					</div>
					<img
						src={preLaunchEventPrizesImage}
						className="object-contain xl:w-[347px] w-[478px] xl:mx-0 mx-auto"
					/>
				</div>
				<div className="md:h-8 h-4" />
				<div className="md:h-[120px] h-[80px] flex flex-col justify-center">
					<p
						className="text-color-bright-yellow md:text-[20px] text-[14px] font-bold text-center cursor-pointer"
						onClick={() => {
							ReactGA.event({
								category: 'pre-launch-event',
								action: 'Clicked on the "Coming Soon, Stay Tune!"',
							});
						}}
					>
						{t('coming_soon_stay_tuned')}
					</p>
					<div className="h-5" />
					<div className="text-center flex flex-row items-center justify-center h-[20px]">
						<div id="unshow-checkbox" className="relative h-full w-[20px]">
							<input
								type="checkbox"
								checked={checked}
								onChange={() => {
									// ignored
								}}
							/>
							<Icon
								name="checkcircle"
								className="checkmark"
								onClick={() => {
									const newChecked = !checked;

									isChecked(newChecked);
									sessionStorage.setItem(
										SessionStorageKey.DISABLE_PRE_LAUNCH_EVENT_BANNER,
										`${newChecked}`
									);
								}}
							/>
						</div>
						<div className="w-4" />
						<p className="md:text-[16px] text-[10px] text-color-white leading-none">
							{"Don't show this again"}
						</p>
					</div>
				</div>
			</div>
			<img
				src={preLaunchEventDeco1Image}
				className="absolute md:w-[165px] w-[66px] bottom-0 left-0 -scale-y-100 -rotate-180 rounded-ee-3xl"
			/>
			<img
				src={preLaunchEventDeco1Image}
				className="absolute md:w-[165px] w-[66px] bottom-0 right-0 rounded-ee-3xl"
			/>
		</Modal>
	);
};

export default PreLaunchEventModal;
