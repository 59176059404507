import React, { useEffect, useRef, useState } from 'react';
import { Stack, Text, Image, Space, Box, Flex, Button } from '@mantine/core';
import { ResponsiveContainer, ViewContainer } from 'components';
import Topbar from 'layouts/Topbar';
import { Trans, useTranslation } from 'react-i18next';
import heroImageDesktop from '../views/assets/home/hero_desktop.png';
import heroImageTablet from '../views/assets/home/hero_tablet.png';
import collectFront from '../views/assets/home/collect_front.png';
import collectBackDesktop from '../views/assets/home/collect_back_desktop.png';
import collectBackMobile from '../views/assets/home/collect_back_mobile.png';
import playFront from '../views/assets/home/play_front.png';
import playBackDesktop from '../views/assets/home/play_back_desktop.png';
import playBackMobile from '../views/assets/home/play_back_mobile.png';
import uniteFront from '../views/assets/home/unite_front.png';
import uniteBackDesktop from '../views/assets/home/unite_back_desktop.png';
import uniteBackMobile from '../views/assets/home/unite_back_mobile.png';
import forCollectorsImage from '../views/assets/home/features_for_collectors_image.png';
import forCollectorsDetailImage1 from '../views/assets/home/features_for_collectors_detail_image_1.png';
import forCollectorsDetailImage2 from '../views/assets/home/features_for_collectors_detail_image_2.png';
import forCollectorsIcon from '../views/assets/home/features_for_collectors_icon.png';
import forSellersImage from '../views/assets/home/features_for_sellers_image.png';
import forSellersDetailImage1 from '../views/assets/home/features_for_sellers_detail_image_1.png';
import forSellersDetailImage2 from '../views/assets/home/features_for_sellers_detail_image_2.png';
import forSellersIcon from '../views/assets/home/features_for_sellers_icon.png';
import forStoresImage from '../views/assets/home/features_for_stores_image.png';
import forStoresDetailImage1 from '../views/assets/home/features_for_stores_detail_image_1.png';
import forStoresDetailImage2 from '../views/assets/home/features_for_stores_detail_image_2.png';
import forStoresIcon from '../views/assets/home/features_for_stores_icon.png';
import floatingCard1 from '../views/assets/home/floating_card_1.png';
import floatingCard2 from '../views/assets/home/floating_card_2.png';
import floatingCard3 from '../views/assets/home/floating_card_3.png';
import floatingCard4 from '../views/assets/home/floating_card_4.png';
import floatingCard5 from '../views/assets/home/floating_card_5.png';
import floatingCard6 from '../views/assets/home/floating_card_6.png';
import backdropDividerYellow from '../views/assets/home/backdrop_divider_yellow.png';
import backdropDividerBlue from '../views/assets/home/backdrop_divider_blue.png';
import backdropSparkle from '../views/assets/home/backdrop_sparkle.png';
import mascotImage from '../views/assets/home/mascot_1.png';
import PreLaunchEventModal from './Temps/PreLaunchEvents/PreLaunchEventModal';
import { SessionStorageKey } from 'enums/sessionStorage.enum';

const Home = () => {
	const { t, i18n } = useTranslation();
	const [openPreLaunchEventBanner, setOpenPreLaunchEventBanner] =
		useState<boolean>(false);

	useEffect(() => {
		const disablePreLaunchEventBanner = sessionStorage.getItem(
			SessionStorageKey.DISABLE_PRE_LAUNCH_EVENT_BANNER
		);

		setOpenPreLaunchEventBanner(disablePreLaunchEventBanner != 'true');
	}, []);

	return (
		<>
			<PreLaunchEventModal opened={openPreLaunchEventBanner} />

			<ViewContainer title="Home">
				{/* Top Navbar */}
				<Topbar />

				<Space className="md:h-16 h-6" />

				{/* Hero section */}
				<section>
					<ResponsiveContainer className="z-10 relative flex flex-wrap-reverse gap-y-6">
						<div
							className={`w-full xl:w-1/2 
								flex flex-wrap justify-center xl:justify-start gap-y-6`}
						>
							<Text
								className={
									`text-color-wizard-blue text-center xl:text-left
						md:text-4xl text-1xl ` +
									(i18n.language === 'ja'
										? `font-DelaGothicOne`
										: `font-['Bungee']`)
								}
								id="hero-text"
							>
								<Trans i18nKey={'your_one_stop_for_everything_tcg'} />
							</Text>

							<Text className={`xl:text-left text-center md:text-xl text-sm`}>
								{t('tcg_unite_makes_tracking_your_cards_simple')}
							</Text>

							<Button
								size="xl"
								radius="lg"
								className={`bg-color-samoan-sun text-color-dark-blue shadow-[0_2px_rgba(197,142,27,1)]`}
							>
								{/* TODO: Redirect to sign up page */}
								{t('coming_soon')}
							</Button>
						</div>

						{/* Hero Image */}
						<div className={`w-full xl:w-1/2`}>
							<img
								src={heroImageDesktop}
								alt="Hero Image"
								className="xl:block hidden"
							/>
							<img
								src={heroImageTablet}
								alt="Hero Image"
								className="xl:hidden block pr-3"
							/>
						</div>
					</ResponsiveContainer>
				</section>

				<Space className="h-20" />

				{/* Marquee */}
				<section>
					<div
						className={
							'marquee md:text-7xl text-4xl text-primary-white bg-primary-old-gold z-10 relative mx-auto py-3 ' +
							(i18n.language === 'ja'
								? `font-DelaGothicOne`
								: `font-['Bungee']`)
						}
					>
						{[...Array(10)].map((_, index) => (
							<span key={index} className={'w-[1220px]'}>
								{t('collect')} &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('play')}
								&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp; {t('unite')}
								&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
							</span>
						))}
					</div>
				</section>

				{/* Interactive Cards section */}
				<section className={`bg-color-wizard-blue`}>
					<div className="z-0 flex flex-wrap justify-center relative">
						<img
							src={backdropDividerBlue}
							className={`xl:w-[1440px] md:w-[968px] w-[640px] max-w-none absolute bottom-0 z-0`}
						/>

						<RotatingInteractiveCards />

						<img
							src={backdropSparkle}
							className={`xl:hidden absolute bottom-8 md:w-[60px] w-[20px] md:ml-[600px] ml-[300px]`}
						/>
					</div>
				</section>

				{/* How TCG Unite works section */}
				<section className={`bg-color-bright-yellow`}>
					<Flex justify={'center'} className="z-0">
						<img
							src={backdropDividerYellow}
							className={`xl:w-[1440px] md:w-[968px] w-[640px] max-w-none absolute`}
						/>
					</Flex>
					<ResponsiveContainer className="z-10 relative ">
						<Stack align="center">
							<img
								src={mascotImage}
								className={`mt-[5rem] md:ml-[3rem] md:w-[20rem] w-[10rem]`}
							/>

							<div className={`relative`}>
								<img
									src={backdropSparkle}
									className={`absolute left-0 -top-10 -z-10 max-w-[10%] scale-[-1]`}
								/>
								<Text
									className={
										`text-color-wizard-blue 
								text-center xl:text-7xl md:text-8xl text-5xl max-w-[40rem] ` +
										(i18n.language === 'ja'
											? `font-DelaGothicOne`
											: `font-['Bungee']`)
									}
									id="how-it-works-text"
								>
									<Trans i18nKey="how_tcg_unite_works" />
								</Text>
								<img
									src={backdropSparkle}
									className={`absolute right-0 bottom-0 -z-10 max-w-[10%]`}
								/>
							</div>

							{/* HOW TCG UNITE WORKS CARDS: DESKTOP */}
							<div className={`xl:block hidden`}>
								<DesktopRevealLeftOnScroll
									preheader={t('for_collectors')}
									title={t('discover_and_value_your_cards')}
									description={t(
										'discover_your_dream_cards_and_get_realtime_value_updates'
									)}
									image={forCollectorsImage}
									detailImage1={forCollectorsDetailImage1}
									icon={forCollectorsIcon}
								/>

								<DesktopRevealRightOnScroll
									preheader={t('for_individual_sellers')}
									title={t('let_go_of_preloved_cards_to_collectors')}
									description={t(
										'let_go_of_preloved_cards_to_collectors_reach_the_right_buyers'
									)}
									image={forSellersImage}
									detailImage1={forSellersDetailImage1}
									icon={forSellersIcon}
								/>

								<DesktopRevealLeftOnScroll
									preheader={t('for_stores')}
									title={t('sell_your_cards_to_tcg_enthusiasts')}
									description={t(
										'target_tcg_enthusiasts_and_sell_your_cards_to_a_dedicated_audience'
									)}
									image={forStoresImage}
									detailImage1={forStoresDetailImage1}
									icon={forStoresIcon}
								/>
							</div>

							{/* HOW TCG UNITE WORKS CARDS: TABLET */}
							<div className={`xl:hidden md:block hidden`}>
								<TabletRevealRightOnScroll
									preheader={t('for_collectors')}
									title={t('discover_and_value_your_cards')}
									description={t(
										'discover_your_dream_cards_and_get_realtime_value_updates'
									)}
									image={forCollectorsImage}
									detailImage1={forCollectorsDetailImage1}
									detailImage2={forCollectorsDetailImage2}
									icon={forCollectorsIcon}
								/>

								<TabletRevealOutwardsOnScroll
									preheader={t('for_individual_sellers')}
									title={t('let_go_of_preloved_cards_to_collectors')}
									description={t(
										'let_go_of_preloved_cards_to_collectors_reach_the_right_buyers'
									)}
									image={forSellersImage}
									detailImage1={forSellersDetailImage1}
									detailImage2={forSellersDetailImage2}
									icon={forSellersIcon}
								/>

								<TabletRevealLeftOnScroll
									preheader={t('for_stores')}
									title={t('sell_your_cards_to_tcg_enthusiasts')}
									description={t(
										'target_tcg_enthusiasts_and_sell_your_cards_to_a_dedicated_audience'
									)}
									image={forStoresImage}
									detailImage1={forStoresDetailImage1}
									detailImage2={forStoresDetailImage2}
									icon={forStoresIcon}
								/>
							</div>

							{/* HOW TCG UNITE WORKS CARDS: MOBILE */}
							<div className={`md:hidden block`}>
								<MobileRevealRightOnScroll
									preheader={t('for_collectors')}
									title={t('discover_and_value_your_cards')}
									description={t(
										'discover_your_dream_cards_and_get_realtime_value_updates'
									)}
									image={forCollectorsImage}
									detailImage1={forCollectorsDetailImage1}
									icon={forCollectorsIcon}
								/>
								<MobileRevealRightOnScroll
									preheader={t('for_individual_sellers')}
									title={t('let_go_of_preloved_cards_to_collectors')}
									description={t(
										'let_go_of_preloved_cards_to_collectors_reach_the_right_buyers'
									)}
									image={forSellersImage}
									detailImage1={forSellersDetailImage1}
									icon={forSellersIcon}
								/>
								<MobileRevealRightOnScroll
									preheader={t('for_stores')}
									title={t('sell_your_cards_to_tcg_enthusiasts')}
									description={t(
										'target_tcg_enthusiasts_and_sell_your_cards_to_a_dedicated_audience'
									)}
									image={forStoresImage}
									detailImage1={forStoresDetailImage1}
									icon={forStoresIcon}
								/>
							</div>
						</Stack>
						<Space className="h-20" />
					</ResponsiveContainer>
				</section>

				{/* Bottom page CTA section */}
				<section>
					<ResponsiveContainer className="z-10 relative xl:px-20 py-[8rem] mt-[3rem]">
						<div className="floating-cards">
							<img
								src={floatingCard1}
								alt="Floating Card"
								className={`animate-float absolute -z-10
							xl:left-[17%] xl:top-0 xl:right-auto xl:bottom-auto
							md:left-[17%] md:top-auto md:right-auto md:bottom-[5%] md:max-w-full
							left-0 top-auto right-auto bottom-[10%] max-w-14`}
							/>
							<img
								src={floatingCard2}
								alt="Floating Card"
								className={`animate-float delay-100 absolute -z-10
							xl:left-[18%] xl:top-auto xl:right-auto xl:bottom-[-10%] xl:max-w-full
							md:left-[20%] md:top-0 md:right-auto md:bottom-auto md:max-w-28
							left-[5%] top-0 right-auto bottom-auto max-w-28`}
							/>
							<img
								src={floatingCard3}
								alt="Floating Card"
								className={`animate-float absolute -z-10
							xl:left-auto xl:top-auto xl:right-[3%] xl:bottom-auto xl:max-w-full
							md:left-auto md:top-[8%] md:right-[27%] md:bottom-auto md:max-w-16
							left-auto top-[8%] right-[15%] bottom-auto max-w-16`}
							/>
							<img
								src={floatingCard4}
								alt="Floating Card"
								className={`animate-float absolute -z-10
							xl:left-10 xl:top-[25%] xl:right-auto xl:bottom-auto xl:max-w-full
							md:left-[3%] md:top-[50%] md:right-auto md:bottom-auto md:max-w-20
							left-auto top-auto right-[-2%] bottom-[20%] max-w-14`}
							/>
							<img
								src={floatingCard5}
								alt="Floating Card"
								className={`animate-float absolute -z-10
							xl:left-auto xl:top-0 xl:right-[15%] xl:bottom-auto
							md:left-auto md:top-[25%] md:right-0 md:bottom-auto
							left-auto top-0 right-0 bottom-auto max-w-16`}
							/>
							<img
								src={floatingCard6}
								alt="Floating Card"
								className={`animate-float absolute -z-10
							xl:left-auto xl:top-auto xl:right-[10%] xl:bottom-[-10%] xl:max-w-64
							md:left-auto md:top-auto md:right-[3%] md:bottom-[-3%] md:max-w-56
							left-auto top-auto right-[35%] bottom-0 max-w-24`}
							/>
						</div>

						<div className={'z-10 flex flex-col'}>
							<Text
								className={
									'md:text-7xl text-4xl text-center text-color-sky-blue max-w-[90%] place-self-center ' +
									(i18n.language === 'ja'
										? `font-DelaGothicOne`
										: `font-['Bungee']`)
								}
								id="bottom-CTA-text"
							>
								<Trans i18nKey="preregister_for_amazing_free_gifts" />
							</Text>

							<Space h="30px" />

							<Text className="text-xl md:font-semibold text-center text-color-wizard-blue">
								{t('dont_wait_join_the_fun_and_snag_your_gifts_today')}
							</Text>

							<Space h="30px" />

							<Flex className="justify-center">
								<Button
									size="xl"
									radius="lg"
									className={`bg-color-samoan-sun text-color-dark-blue shadow-[0_2px_rgba(197,142,27,1)] md:px-[4rem] px-[2rem]`}
								>
									{/* TODO: Redirect to sign up page */}
									{t('coming_soon')}
								</Button>
							</Flex>
						</div>
					</ResponsiveContainer>

					<ResponsiveContainer className="my-4">
						<Flex className="justify-center">
							<Text
								className={`text-color-dark-blue text-[0.55rem] text-center max-w-sm`}
							>
								{t(
									'disclaimer_the_images_shown_are_for_illustration_purposes_only'
								)}
							</Text>
						</Flex>
					</ResponsiveContainer>
				</section>
			</ViewContainer>
		</>
	);
};

export default Home;

interface RevealOnScrollProps {
	preheader: string;
	title: string;
	description: string;
	image: string;
	detailImage1: string;
	detailImage2?: string;
	icon: string;
}

const DesktopRevealLeftOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesSlideLeftOnReveal = `transition-transform duration-700 ease-in-out
        ${isRevealed ? 'translate-none' : 'translate-x-[400px]'}`;

	const classesFadeLeftOnReveal = `transition-all duration-700 ease-in-out
        ${isRevealed ? 'opacity-100' : 'opacity-0'} 
		${isRevealed ? 'translate-none' : 'translate-x-[5rem]'}`;

	const classesFadeRightOnReveal = `transition-all duration-700 ease-in-out
        ${isRevealed ? 'opacity-100' : 'opacity-0'} 
		${isRevealed ? 'translate-none' : '-translate-x-[5rem]'}`;

	const classesScaleOnReveal = `transition-transform duration-700 ease-in-out
        ${isRevealed ? 'scale-none' : 'scale-x-[40%]'}`;

	return (
		<div className={'w-[90%] relative'}>
			<Flex className={`relative w-full p-8`} gap={'xl'} align={'center'}>
				<Box
					className={`h-[70%] w-full bg-color-white rounded-xl absolute -z-10 ${classesScaleOnReveal}`}
				/>
				<Flex align={'center'} className={'w-[45%] px-8'}>
					<img
						src={image}
						alt="Feature Image"
						className={`w-[15rem] ` + classesSlideLeftOnReveal}
					/>
					<img
						src={detailImage1}
						alt="Feature Image"
						className={`w-[10rem] -translate-x-[1.5rem] ${classesFadeLeftOnReveal}`}
					/>
					<img
						src={icon}
						alt="Feature Image"
						className={`w-[10rem] -translate-x-[2.5rem] ${classesFadeLeftOnReveal}`}
					/>
				</Flex>

				<Stack
					align="center"
					justify="center"
					className={`w-[55%] ${classesFadeRightOnReveal}`}
				>
					<Text
						className={
							`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
							(i18n.language === 'ja'
								? `font-DelaGothicOne`
								: `font-['Bungee']`)
						}
					>
						{preheader}
					</Text>
					<Text
						className={
							`text-color-black text-4xl text-left w-full ` +
							(i18n.language === 'ja'
								? `font-DelaGothicOne`
								: `font-['Bungee']`)
						}
						ref={ref}
					>
						{/* Trigger reveal when scrolled at this point */}
						{title}
					</Text>
					<Text
						className={`text-color-black text-base text-left md:font-semibold w-full `}
					>
						{description}
					</Text>
				</Stack>
			</Flex>
		</div>
	);
};

const DesktopRevealRightOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesSlideRightOnReveal = `transition-transform duration-700 ease-in-out
			${isRevealed ? 'translate-none' : '-translate-x-[350px]'}`;

	const classesFadeLeftOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : 'translate-x-[5rem]'}`;

	const classesFadeRightOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : '-translate-x-[5rem]'}`;

	const classesScaleOnReveal = `transition-transform duration-700 ease-in-out
			${isRevealed ? 'scale-none' : 'scale-x-[40%]'}`;

	return (
		<div className={'w-[90%] relative'}>
			<Flex className={`relative w-full p-8`} gap={'xl'} align={'center'}>
				<Box
					className={`h-[70%] w-full bg-color-white rounded-xl absolute -z-10 ${classesScaleOnReveal}`}
				/>
				<Stack
					align="center"
					justify="center"
					className={`w-[55%] pl-8 ${classesFadeLeftOnReveal}`}
				>
					<Text
						className={
							`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
							(i18n.language === 'ja'
								? `font-DelaGothicOne`
								: `font-['Bungee']`)
						}
					>
						{preheader}
					</Text>
					<Text
						className={
							`text-color-black text-4xl text-left w-full ` +
							(i18n.language === 'ja'
								? `font-DelaGothicOne`
								: `font-['Bungee']`)
						}
						ref={ref}
					>
						{/* Trigger reveal when scrolled at this point */}
						{title}
					</Text>
					<Text
						className={`text-color-black text-base text-left md:font-semibold w-full `}
					>
						{description}
					</Text>
				</Stack>
				<Flex align={'center'} className={`w-[45%]`}>
					<img
						src={icon}
						alt="Feature Image"
						className={`w-[10rem] translate-x-[1.5rem] ${classesFadeRightOnReveal}`}
					/>
					<img
						src={detailImage1}
						alt="Feature Image"
						className={`w-[10rem] translate-x-[1.5rem] ${classesFadeRightOnReveal}`}
					/>
					<img
						src={image}
						alt="Feature Image"
						className={`w-[15rem] ` + classesSlideRightOnReveal}
					/>
				</Flex>
			</Flex>
		</div>
	);
};

const TabletRevealRightOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	detailImage2,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesSlideLeftOnReveal = `transition-transform duration-700 ease-in-out
			${isRevealed ? 'translate-none' : 'translate-x-[20dvw]'}`;

	const classesFadeRightOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : '-translate-x-[10%]'}`;

	return (
		<Box
			key={title}
			className="basis-full relative rounded-xl bg-color-white w-full mb-4"
		>
			<Stack align="center" className="gap-y-2 w-[80%] p-10">
				<Text
					className={
						`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{preheader}
				</Text>

				<Text
					className={
						`text-color-black md:text-4xl text-xl text-left w-full ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{title}
				</Text>

				<Text
					className={`text-color-black text-sm text-left md:font-semibold w-full `}
				>
					{description}
				</Text>
			</Stack>

			<Image src={icon} className={`w-[15%] absolute top-6 right-5`} />

			<Stack align="center" justify="center" className="pb-7">
				{/* Trigger reveal when scrolled at this point */}
				<Box
					className={`bg-color-bright-yellow w-[85%] h-28 absolute`}
					ref={ref}
				/>

				<Flex
					align={'center'}
					justify={'center'}
					gap={'xl'}
					className={'px-[6rem] z-10'}
				>
					<img
						src={image}
						alt="Feature Image"
						className={`w-1/3 h-[220px] object-cover object-top ${classesSlideLeftOnReveal}`}
					/>
					<img
						src={detailImage1}
						className={`w-1/3 ${classesFadeRightOnReveal}`}
					/>
					<img
						src={detailImage2}
						className={`w-1/3 ${classesFadeRightOnReveal}`}
					/>
				</Flex>
			</Stack>
		</Box>
	);
};

const TabletRevealOutwardsOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	detailImage2,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesFadeLeftOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : 'translate-x-[30%]'}`;

	const classesFadeRightOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : '-translate-x-[30%]'}`;

	return (
		<Box
			key={title}
			className="basis-full relative rounded-xl bg-color-white w-full mb-4"
		>
			<Stack align="center" className="gap-y-2 w-[80%] p-10">
				<Text
					className={
						`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{preheader}
				</Text>

				<Text
					className={
						`text-color-black md:text-4xl text-xl text-left w-full ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{title}
				</Text>

				<Text
					className={`text-color-black text-sm text-left md:font-semibold w-full `}
				>
					{description}
				</Text>
			</Stack>

			<Image src={icon} className={`w-[15%] absolute top-6 right-5`} />

			<Stack align="center" justify="center" className="pb-7">
				<Box
					className={`bg-color-bright-yellow w-[85%] h-28 absolute`}
					ref={ref}
				/>
				<Flex
					align={'center'}
					justify={'center'}
					gap={'xl'}
					className={'px-[6rem] z-10'}
				>
					<img
						src={detailImage1}
						className={`w-1/3 ${classesFadeLeftOnReveal}`}
					/>
					<img
						src={image}
						alt="Feature Image"
						className={`w-1/3 max-h-[220px] object-cover object-top `}
					/>
					<img
						src={detailImage2}
						className={`w-1/3 ${classesFadeRightOnReveal}`}
					/>
				</Flex>
			</Stack>
		</Box>
	);
};

const TabletRevealLeftOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	detailImage2,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesSlideRightOnReveal = `transition-transform duration-700 ease-in-out
			${isRevealed ? 'translate-none' : '-translate-x-[20dvw]'}`;

	const classesFadeLeftOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : 'translate-x-[10%]'}`;

	return (
		<Box
			key={title}
			className="basis-full relative rounded-xl bg-color-white w-full mb-4"
		>
			<Stack align="center" className="gap-y-2 w-[80%] p-10">
				<Text
					className={
						`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{preheader}
				</Text>

				<Text
					className={
						`text-color-black md:text-4xl text-xl text-left w-full ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{title}
				</Text>

				<Text
					className={`text-color-black text-sm text-left md:font-semibold w-full `}
				>
					{description}
				</Text>
			</Stack>

			<Image src={icon} className={`w-[15%] absolute top-6 right-5`} />

			<Stack align="center" justify="center" className="pb-7">
				<Box
					className={`bg-color-bright-yellow w-[85%] h-28 absolute`}
					ref={ref}
				/>
				<Flex
					align={'center'}
					justify={'center'}
					gap={'xl'}
					className={'px-[6rem] z-10'}
				>
					<img
						src={detailImage2}
						className={`w-1/3 ${classesFadeLeftOnReveal}`}
					/>
					<img
						src={detailImage1}
						className={`w-1/3 ${classesFadeLeftOnReveal}`}
					/>
					<img
						src={image}
						alt="Feature Image"
						className={`w-1/3 h-[220px] object-cover object-top ${classesSlideRightOnReveal}`}
					/>
				</Flex>
			</Stack>
		</Box>
	);
};

const MobileRevealRightOnScroll: React.FC<RevealOnScrollProps> = ({
	preheader,
	title,
	description,
	image,
	detailImage1,
	icon,
}) => {
	const [isRevealed, setIsRevealed] = useState(false);
	const ref = useRef(null);
	const { i18n } = useTranslation();

	useEffect(() => {
		const scrollObserver = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				setIsRevealed(true);
				scrollObserver.unobserve(entry.target);
			}
		});

		if (ref.current) {
			scrollObserver?.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				scrollObserver.unobserve(ref.current);
			}
		};
	}, []);

	const classesSlideRightOnReveal = `transition-transform duration-700 ease-in-out
			${isRevealed ? 'translate-none' : '-translate-x-[40%]'}`;

	const classesFadeLeftOnReveal = `transition-all duration-700 ease-in-out
			${isRevealed ? 'opacity-100' : 'opacity-0'} 
			${isRevealed ? 'translate-none' : 'translate-x-[10%]'}`;

	return (
		<Box className="basis-full relative rounded-xl bg-color-white w-full mb-4">
			<Stack align="center" className="gap-y-2 w-[85%] p-6">
				<Text
					className={
						`text-color-wizard-blue text-left w-full md:text-2xl text-base ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{preheader}
				</Text>

				<Text
					className={
						`text-color-black md:text-4xl text-xl text-left w-full ` +
						(i18n.language === 'ja' ? `font-DelaGothicOne` : `font-['Bungee']`)
					}
				>
					{title}
				</Text>

				<Text
					className={`text-color-black text-sm text-left md:font-semibold w-full `}
				>
					{description}
				</Text>
			</Stack>

			<img src={icon} className={`w-[15%] absolute top-6 right-5`} />

			<Stack align="center" className="pb-7">
				<Flex align={'center'} justify={'center'} className={'z-10'}>
					<Box
						className={`bg-color-bright-yellow w-[85%] h-28 absolute -z-10`}
						ref={ref}
					/>
					{/* Trigger reveal when scrolled at this point */}
					<img
						src={detailImage1}
						className={`w-2/5 ${classesFadeLeftOnReveal}`}
					/>
					<img
						src={image}
						alt="Feature Image"
						className={`w-1/2 h-[210px] ${classesSlideRightOnReveal} object-cover object-top`}
					/>
				</Flex>
			</Stack>
		</Box>
	);
};

const RotatingInteractiveCards = () => {
	// Track and handle current active card
	const [activeCardIndex, setActiveCardIndex] = useState<number>(0);
	const setCurrentActiveCard = (index: number) => {
		if (index !== activeCardIndex) {
			setActiveCardIndex(index);
			startTimer();
		}
	};

	// Handle timer for automatic card rotation/movement
	const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
	const startTimer = () => {
		if (timer) clearInterval(timer);

		const newTimer = setInterval(() => {
			setActiveCardIndex((oldIndex) => (oldIndex + 1) % 3);
		}, 3000);

		setTimer(newTimer);
	};

	// Start timer on mount
	useEffect(() => {
		startTimer();
		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, []);

	const cardsArray = [
		{
			cardFrontImg: collectFront,
			cardBackImgDesktop: collectBackDesktop,
			cardBackImgMobile: collectBackMobile,
		},
		{
			cardFrontImg: playFront,
			cardBackImgDesktop: playBackDesktop,
			cardBackImgMobile: playBackMobile,
		},
		{
			cardFrontImg: uniteFront,
			cardBackImgDesktop: uniteBackDesktop,
			cardBackImgMobile: uniteBackMobile,
		},
	];

	return (
		<div
			className={`z-10 xl:mt-[100px] md:mt-[50px] mt-[10px] xl:mb-[300px] md:mb-[150px] py-12`}
		>
			<div className={'relative xl:h-[450px] md:h-[350px] h-[250px]'}>
				{/* Desktop view */}
				<div className={`hidden xl:flex flex-nowrap justify-center`}>
					{cardsArray.map((card, index) => {
						const cardPos = ((((index - activeCardIndex + 1) % 3) + 3) % 3) + 1;

						return (
							<div
								key={index}
								className={`flip-card-desktop shrink-0 absolute order-${cardPos}`}
								onClick={() => setCurrentActiveCard(index)}
							>
								<div
									className={`flip-card-inner ${
										activeCardIndex === index ? 'flip-card-inner-flipped' : ''
									}`}
								>
									<div className="flip-card-back">
										<img src={card.cardBackImgDesktop} />
									</div>
									<div className="flip-card-front">
										<img src={card.cardFrontImg} />
									</div>
								</div>
							</div>
						);
					})}
				</div>

				{/* Tablet/Mobile view */}
				<div
					className={`xl:hidden flex flex-nowrap pt-8 justify-center mr-12 md:scale-[1] scale-[0.55]`}
				>
					{cardsArray.map((card, index) => {
						const cardPos = ((((activeCardIndex - index) % 3) + 3) % 3) + 1;
						return (
							<div
								key={index}
								className={`flip-card-mobile shrink-0 absolute
								order-${cardPos} `}
								onClick={() => setCurrentActiveCard(index)}
							>
								<div
									className={`flip-card-inner ${
										activeCardIndex === index ? 'flip-card-inner-flipped' : ''
									}`}
								>
									<div className="flip-card-back">
										<img src={card.cardBackImgMobile} />
									</div>
									<div className="flip-card-front">
										<img src={card.cardFrontImg} />
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
