import React, { useState } from 'react';
import {
	Stack,
	Text,
	Image,
	Space,
	Box,
	Flex,
	Grid,
	UnstyledButton,
	Collapse,
} from '@mantine/core';
import { ResponsiveContainer, ViewContainer } from 'components';
import backdropDesktopImage from '../views/assets/about_us/backdrop_desktop.png';
import backdropTabletImage from '../views/assets/about_us/backdrop_tablet.png';
import backdropMobileImage from '../views/assets/about_us/backdrop_mobile.png';
import mascotImage from '../views/assets/about_us/mascot.png';
import goalsImage from '../views/assets/about_us/goals.png';
import missionImage from '../views/assets/about_us/mission.png';
import visionImage from '../views/assets/about_us/vision.png';
import prizesImage from '../views/assets/about_us/prizes.png';
import Topbar from 'layouts/Topbar';
import Icon from 'assets/icons';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
	const { t } = useTranslation();

	const [benefitIndex, setBenefitIndex] = useState<number | null>(null);

	const aimsArray = [
		{
			title: t('our_goals'),
			description: t('to_cultivate_thriving_and_engaged_community'),
			image: goalsImage,
		},
		{
			title: t('our_mission'),
			description: t('to_bridge_the_gap_between_international'),
			image: missionImage,
		},
		{
			title: t('our_vision'),
			description: t('to_be_the_leading_platform_for_tcg_enthusiasts'),
			image: visionImage,
		},
	];

	const benefitsArray = [
		{
			title: t('transparency_and_accountability'),
			subtitle1: t('community_funds'),
			description1: t('we_will_provide_regular_updates_on_the_community_funds'),
			subtitle2: t('financial_accountability'),
			description2: t(
				'we_will_adhere_to_strict_financial_standards_of_community_funds'
			),
		},
		{
			title: t('community_driven_initiatives'),
			subtitle1: t('community_grants'),
			description1: t(
				'we_plan_to_allocate_community_funds_to_support_grassroots_tcg_initiatives'
			),
			subtitle2: t('community_partnerships'),
			description2: t('we_will_actively_seek_partnerships_with_others'),
		},
		{
			title: t('user_feedback_and_engagement'),
			subtitle1: t('listening_to_our_community'),
			description1: t('we_value_your_feedback_and_suggestions'),
			subtitle2: t('community_driven_features'),
			description2: t(
				'we_will_prioritize_the_development_of_features_and_functionalities'
			),
		},
	];

	return (
		<ViewContainer title="About Us">
			<Topbar></Topbar>
			<Box className="xl:h-[40rem] md:h-[55rem] h-[48rem]">
				<img
					src={backdropDesktopImage}
					alt="Backdrop"
					className="absolute top-0 h-[45rem] w-full z-1 object-cover object-top xl:block hidden"
				/>
				<img
					src={backdropTabletImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-[58rem] w-full z-1 object-cover object-top xl:hidden md:block hidden"
				/>
				<img
					src={backdropMobileImage}
					alt="Backdrop"
					className="absolute top-0 bottom-0 h-[52rem] w-full z-1 object-cover object-top md:hidden block"
				/>
				<ResponsiveContainer className="z-10 relative">
					<Flex className="items-center justify-center gap-12 xl:flex-row flex-col-reverse">
						<Stack className="gap-8 basis-1/2">
							<Text className="text-secondary-white md:text-7xl text-5xl font-['Bungee'] xl:text-left text-center uppercase">
								{t('about_us')}
							</Text>
							<Text className="text-secondary-white text-sm text-left">
								{t('tcg_unite_passionate_about_elevating_the_tcg_experience')}
							</Text>
						</Stack>
						<Box className="basis-1/2">
							<Image
								src={mascotImage}
								alt="Mascot"
								fit="contain"
								className="md:w-[30rem] w-80"
							/>
						</Box>
					</Flex>
				</ResponsiveContainer>
			</Box>
			<Space className="h-28" />
			<ResponsiveContainer className="z-10 relative">
				<Stack className="gap-32">
					{aimsArray.map(({ title, description, image }, index) => (
						<Flex
							key={title}
							className={
								(index % 2 !== 0 ? 'xl:flex-row-reverse' : '') +
								' gap-20 xl:items-start items-center xl:flex-row flex-col'
							}
						>
							<Image
								src={image}
								alt={title}
								fit="contain"
								className="xl:w-[30rem] md:w-64 w-52"
							/>
							<Stack className="gap-8">
								<Text className="text-primary-brilliant-blue text-5xl font-['Bungee'] xl:text-left text-center uppercase">
									{title}
								</Text>
								<Text className="text-secondary-black text-sm text-left">
									{description}
								</Text>
							</Stack>
						</Flex>
					))}
				</Stack>
			</ResponsiveContainer>
			<Space className="h-28" />
			<Box className="bg-primary-brilliant-blue">
				<ResponsiveContainer className="py-14">
					<Flex className="gap-20 xl:items-end items-center xl:flex-row flex-col-reverse">
						<Stack className="gap-4">
							<Text className="text-primary-white md:text-5xl text-4xl font-['Bungee'] text-left">
								{t('building_a_community_that_gives_back')}
							</Text>
							<Text className="text-secondary-greyish-blue text-md text-left font-bold">
								{t('we_tcgunite_believe_in_the_power_of_community')}
							</Text>
						</Stack>
						<Image
							src={prizesImage}
							alt="Prizes"
							fit="contain"
							className="xl:w-96 md:w-64 w-52"
						/>
					</Flex>
					<Space className="h-20" />
					<Box className="xl:block hidden">
						{benefitIndex != null ? (
							<Box className="bg-primary-white-100 px-8 py-10 max-w-none rounded-3xl border-1 mb-3 xl:block hidden">
								<Flex className="justify-between items-center">
									<Text className="text-primary-white text-3xl text-left font-semibold">
										{benefitsArray[benefitIndex].title}
									</Text>
									<UnstyledButton onClick={() => setBenefitIndex(null)}>
										<Box className="rounded-full border-1 p-1">
											<Icon
												name="southwestarrow"
												className="text-secondary-white"
											/>
										</Box>
									</UnstyledButton>
								</Flex>
								<Space className="h-14" />
								<Text className="text-primary-white text-md font-semibold">
									{benefitsArray[benefitIndex].subtitle1}
								</Text>
								<Space h="xs" />
								<Text className="text-primary-white text-sm">
									{benefitsArray[benefitIndex].description1}
								</Text>
								<Space className="h-10" />
								<Text className="text-primary-white text-md font-semibold">
									{benefitsArray[benefitIndex].subtitle2}
								</Text>
								<Space h="xs" />
								<Text className="text-primary-white text-sm">
									{benefitsArray[benefitIndex].description2}
								</Text>
							</Box>
						) : null}
						<Grid grow gutter="md" align="stretch">
							{benefitsArray.map((benefit, index) => {
								if (benefitIndex === index) return null;

								return (
									<Grid.Col
										key={index}
										xl={benefitIndex != null ? 4 : 6}
										span={12}
									>
										<Box className="bg-primary-white-100 p-8 py-14 max-w-none rounded-3xl border-1 h-full flex justify-between items-center">
											<Text className="text-primary-white text-xl text-left font-semibold">
												{benefit.title}
											</Text>
											<UnstyledButton onClick={() => setBenefitIndex(index)}>
												<Box className="rounded-full border-1 p-1 md:block hidden">
													<Icon
														name="northeastarrow"
														className="text-secondary-white"
													/>
												</Box>
											</UnstyledButton>
										</Box>
									</Grid.Col>
								);
							})}
						</Grid>
					</Box>
					<Box className="xl:hidden block">
						<Grid grow gutter="md" align="stretch">
							{benefitsArray.map((benefit, index) => {
								return (
									<Grid.Col
										key={index}
										xl={benefitIndex != null ? 4 : 6}
										span={12}
									>
										<UnstyledButton
											className="w-full"
											onClick={() =>
												setBenefitIndex(index === benefitIndex ? null : index)
											}
										>
											<Box className="transition bg-primary-white-100 p-8 py-14 rounded-3xl border-1">
												<Flex className="justify-between items-center">
													<Text className="text-primary-white text-xl text-left font-semibold">
														{benefit.title}
													</Text>
													<Box className="rounded-full border-1 p-1 md:block hidden">
														<Icon
															name={
																index === benefitIndex
																	? 'southwestarrow'
																	: 'northeastarrow'
															}
															className="text-secondary-white"
														/>
													</Box>
												</Flex>
												<Collapse in={benefitIndex === index}>
													<Box className="xl:hidden block">
														<Space className="h-14" />
														<Text className="text-primary-white text-md font-semibold">
															{benefit.subtitle1}
														</Text>
														<Space h="xs" />
														<Text className="text-primary-white text-sm">
															{benefit.description1}
														</Text>
														<Space className="h-10" />
														<Text className="text-primary-white text-md font-semibold">
															{benefit.subtitle2}
														</Text>
														<Space h="xs" />
														<Text className="text-primary-white text-sm">
															{benefit.description2}
														</Text>
													</Box>
												</Collapse>
											</Box>
										</UnstyledButton>
									</Grid.Col>
								);
							})}
						</Grid>
					</Box>
					<Space className="h-28" />
				</ResponsiveContainer>
			</Box>
			<Space className="h-28" />
			<ResponsiveContainer>
				<Box className="journey-hero rounded-3xl md:p-36 p-10">
					<Stack className="gap-10">
						<Text className="text-primary-white md:text-7xl text-4xl font-['Bungee'] text-center">
							{t('start_your_tcg_journey_with_us')}
						</Text>
						<Text className="text-primary-white text-md text-center font-bold">
							{t('join_the_fun_for_a_chance_to_win_giveaways')}!
						</Text>
						<Flex className="justify-center">
							<Box className="bg-secondary-white rounded-3xl px-8 py-2.5 drop-shadow-lg">
								<Text className="font-semibold text-secondary-black text-md text-center">
									{/* TODO: Redirect to sign up page */}
									{t('coming_soon')}
								</Text>
							</Box>
						</Flex>
					</Stack>
				</Box>
			</ResponsiveContainer>
			<Space className="h-28" />
		</ViewContainer>
	);
};

export default AboutUs;
