import {
	FooterProps,
	Image,
	UnstyledButton,
	Footer,
	Text,
	Flex,
	Anchor,
	Box,
} from '@mantine/core';
import React from 'react';
import { useNavigate } from '../lib/navigate';
import tcguniteLogo from '../views/assets/logo.png';
import { ResponsiveContainer } from 'components';
import Icon from 'assets/icons';
import { HomeRoutes } from 'router/constants/Home.routes';
import { useTranslation } from 'react-i18next';

export type CustomFooterProps = Omit<FooterProps, 'children' | 'height'>;

const CustomFooter = React.forwardRef<HTMLElement, CustomFooterProps>(
	({ ...rest }, ref) => {
		const navigate = useNavigate();
		const { t } = useTranslation();

		return (
			<Footer
				className="relative border-none w-full bg-secondary-black py-10"
				height="100%"
				{...rest}
				ref={ref}
			>
				<Box className="h-10"></Box>
				<ResponsiveContainer className="">
					<Flex className="gap-16 xl:flex-row flex-col">
						<Flex
							direction="row"
							className="items-start md:justify-start justify-center"
						>
							<UnstyledButton onClick={() => navigate(HomeRoutes.Home)}>
								<Image width={60} fit="contain" src={tcguniteLogo}></Image>
							</UnstyledButton>
						</Flex>
						<Flex className="grow md:items-start items.center gap-x-10 xl:gap-y-0 gap-y-8 md:flex-row flex-col justify-between">
							<Flex
								direction="column"
								className="gap-2.5 md:items-start items-center"
							>
								<Text className="font-bold text-sm text-secondary-white uppercase">
									{t('company')}
								</Text>
								<UnstyledButton
									className="text-xs text-secondary-white"
									onClick={() => navigate(HomeRoutes.AboutUs)}
								>
									{t('about_us')}
								</UnstyledButton>
								<UnstyledButton
									className="text-xs text-secondary-white"
									onClick={() => navigate(HomeRoutes.PrivacyPolicy)}
								>
									{t('privacy_policy')}
								</UnstyledButton>
								<Text className="text-xs text-secondary-white">
									{t('term_of_services')}
								</Text>
								<UnstyledButton
									className="text-xs text-secondary-white"
									onClick={() => navigate(HomeRoutes.Careers)}
								>
									{t('careers')}
								</UnstyledButton>
							</Flex>
							<Flex className="gap-5 xl:flex-row flex-col">
								<Flex
									direction="column"
									className="gap-2.5 md:items-start items-center"
								>
									<Text className="font-bold text-sm text-secondary-white uppercase">
										{t('contact_us')}
									</Text>
									<Text className="text-xs text-secondary-white">
										contact@tcgunite.co
									</Text>
									{/* <Text className="text-xs text-secondary-white">
										No., Street Name, Postcode, City, State
									</Text> */}
								</Flex>
								<Flex
									direction="column"
									className="gap-2.5 md:items-start items-center"
								>
									<Text className="font-bold text-sm text-secondary-white uppercase">
										{t('connect_with_us')}
									</Text>
									<Flex direction="row" className="gap-3 align-items-start">
										<Anchor
											href="https://www.facebook.com/profile.php?id=61563242706220"
											target="_blank"
										>
											<Icon name="facebook" className="text-secondary-white" />
										</Anchor>
										<Anchor
											href="https://www.instagram.com/tcgunite/"
											target="_blank"
										>
											<Icon name="instagram" className="text-secondary-white" />
										</Anchor>
										<Anchor href="https://x.com/tcgunite" target="_blank">
											<Icon name="twitter" className="text-secondary-white" />
										</Anchor>
										<Anchor
											href="https://www.youtube.com/@tcgunite"
											target="_blank"
										>
											<Icon name="youtube" className="text-secondary-white" />
										</Anchor>
										<Anchor
											href="https://www.tiktok.com/@tcgunite"
											target="_blank"
										>
											<Icon
												name="tiktok"
												className="w-4 h-5 fill-secondary-white"
											/>
										</Anchor>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</ResponsiveContainer>
				<Box className="h-10"></Box>
				<ResponsiveContainer>
					<Text className="text-secondary-white text-sm hover:no-underline md:text-left text-center">
						@ {new Date().getFullYear()} TCG Unite
					</Text>
				</ResponsiveContainer>
			</Footer>
		);
	}
);

CustomFooter.displayName = 'CustomFooter';

export default CustomFooter;
