/* eslint-disable no-mixed-spaces-and-tabs */
import Icon from 'assets/icons';
import React, { useEffect, useState } from 'react';

export type ModalProps = React.PropsWithChildren<{
	onClose?: () => void;
	opened: boolean;
	className?: string;
}>;

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
	({ children, onClose, opened, className, ...rest }, ref) => {
		const [isOpened, setIsOpened] = useState<boolean>(opened);

		useEffect(() => {
			setIsOpened(opened);
		}, [opened]);

		// TODO: Change modal transition to more seamless way
		return (
			<div
				className={`absolute w-full h-full z-[999] transition-all ${
					isOpened ? 'top-0' : 'top-[-100%]'
				}`}
			>
				<div
					ref={ref}
					className={`absolute w-full h-full overflow-auto bg-color-black/[.5] transition-all ${
						isOpened ? 'opacity-100 top-0' : '!opacity-0 top-[-100%]'
					}`}
					{...rest}
				></div>
				<div
					className={`absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] bg-color-white p-3 xl:w-[880px] md:w-[696px] w-[280px] mx-auto rounded-3xl ${className} transition-all duration-500 ${
						!isOpened && 'top-[-100%]'
					}`}
				>
					<button
						onClick={() => {
							if (onClose) {
								onClose();
							} else {
								setIsOpened(false);
							}
						}}
						className={`bg-color-bit-of-blue absolute right-3 top-3 rounded-full md:h-[44px] h-[18px] md:w-[44px] w-[18px]`}
					>
						<Icon
							name="xmark"
							className="fill-[#0F172A] md:h-[13.5px] md:w-[13.5px] h-[5px] w-[5px] mx-auto"
						/>
					</button>
					{children}
				</div>
			</div>
		);
	}
);

Modal.displayName = 'Modal';
