import React, { Component } from 'react';
import {
	NavLinkProps as MantineNavLinkProps,
	Text,
	UnstyledButton,
} from '@mantine/core';
import { useNavigate } from '../lib/navigate';
import { HomeRoutes } from 'router/constants/Home.routes';
import { useLocation } from 'react-router-dom';

export type NavLinkProps = {
	to?: string;
} & Omit<MantineNavLinkProps, 'icon'>;

const NavLink = React.forwardRef<HTMLButtonElement, NavLinkProps>(
	({ to, label }, ref) => {
		const navigate = useNavigate();
		const { pathname } = useLocation();

		const textColor =
			pathname === HomeRoutes.AboutUs
				? 'text-color-white'
				: pathname === HomeRoutes.Home
				? 'xl:text-color-dark-blue md:text-color-white'
				: 'text-color-dark-blue';

		return (
			<UnstyledButton
				className={`font-semibold text-sm text-center py-1 `}
				onClick={() => navigate(to ?? HomeRoutes.Home)}
				ref={ref}
			>
				<Text weight="600" className={`leading-none ` + textColor}>
					{label}
				</Text>
			</UnstyledButton>
		);

		return <Component />;
	}
);

NavLink.displayName = 'NavLink';
//
export default NavLink;
